.full-height {
  position: relative;
  min-height: 100vh;
  background-color: black; /* Set the background to black */
  overflow: hidden;
}

.rounded-image {
  border-radius: 15px; /* Adjust as needed */
}

.social-links {
  padding: 20px 0;
}

.social-icon {
  margin: 0 10px;
  font-size: 24px; /* Adjust size as needed */
  color: #000; /* Adjust to match your site's theme */
}

.social-icon:hover {
  color: #555; /* Darker shade on hover */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Adjust the height, background color, and text color as needed */
}

.dark-grey-button {
  background-color: #343a40; /* Dark grey */
  border-color: #343a40;
  color: #ffffff; /* White text */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.dark-grey-button:hover {
  background-color: #23272b; /* Slightly darker grey on hover */
  border-color: #23272b;
}



/* Add this to your existing CSS */

.floating-image {
  position: absolute;
  width: 50px; /* Adjust based on your image size */
  height: 75px; /* Adjust based on your image size */
  background-image: url('./assets/ChrisLane.png'); /* Path to your floating image */
  background-size: cover;
  animation: float 25s infinite linear;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-100px) translateX(150px);
  }
  50% {
    transform: translateY(100px) translateX(-150px);
  }
  75% {
    transform: translateY(-50px) translateX(-100px);
  }
}

/* Ensure your other styles remain intact */


.overlay-content {
  position: relative;
  z-index: 1; /* Ensures content is above the starry background */
}


.overlay-content {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
  text-align: center;
}

.timer-text {
  font-size: 4rem; /* Adjust size as needed */
  color: rgba(255, 255, 255, 0.75); /* Slightly faded white text for the timer */
}

.btn-warning {
  background-color: #959595;
  border: none;
  transition: transform 0.2s ease-in-out;
}

.btn-warning:hover {
  transform: scale(1.05);
  background-color: #5a5a5a;
}

@media (max-width: 768px) {
  .timer-text {
    font-size: 2rem; /* Smaller font for responsive design */
  }
}

/* EventDetails Page Enhancements */
.event-details-section {
  padding: 2rem 0;
}

.event-details-section h2 {
  margin-bottom: 1rem;
  color: #333; /* Dark text color for better readability */
}

.event-details-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555; /* Slightly lighter text color for a softer look */
}

@media (max-width: 768px) {
  .event-details-section .row {
    flex-direction: column !important; /* Stack image and text on smaller screens */
  }
  .event-details-section img {
    margin-bottom: 2rem;
  }
}

